import React from 'react'
import Layout from '../components/layout'

import {Card, CardBody} from '../components/card';


export default class Download extends React.Component {

    render()
    {
        return (

            <Layout lang={'en'}
                    centerClassName={'bg-blue-gradient'}
                    location={this.props.location.pathname}
                    title={'Download 2stitch Studio'}
                    description={'Download 2stitch Studio for Windows and macOS.'}
            >

                <div className="container mx-auto pt-4 text-blue-darkest pb-12">


                    <div className="lg:w-2/3 mx-auto leading-normal">

                        <h2 className={'text-4xl mb-4'}>Early access program</h2>

                        <p className={'mb-2'}>
                            Thanks for your interest in 2stich Studio.
                            We must admit that the software is still under development and
                            the final version is not yet available for download.
                        </p>
                        <p>
                            Please sign up to our mailing list and get
                            early access to our pre-release versions as soon
                            as they become available.
                        </p>

                        <Card className={'mt-8 mb-8 bg-light-blue-25'}>
                            <CardBody>
                                <p className="my-4 text-lg font-semibold text-light-blue-900">
                                        Newsletter subscribers get a 10% discount code for the final product.
                                </p>
                                <p>
                                    Yes, please subscribe me to the 2stitch newsletter.

                                    The newsletter is sent
                                    occasionally
                                    with
                                    information about the release date of the final product, development
                                    progress,
                                    offers, discounts and
                                    our early access program.
                                </p>

                                <iframe
                                    className={'my-4'}
                                    title={"newsletter-signup"}
                                    id={'signup-frame'}
                                    style={{width: '100%', height: '6rem'}}
                                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                                    src="https://app.mailjet.com/widget/iframe/30Gx/93b"
                                >
                                </iframe>

                            </CardBody>
                        </Card>
                        <p className="text-blue leading-tight">
                            Please visit our <a href="/privacy/">Terms Of Service</a> to
                            learn more
                            about our newsletter service provider MailJet, newsletter success tracking,
                            subscriptions and
                            your right
                            to object.
                            You can unsubscribe at any time.
                        </p>
                    </div>
                </div>
            </Layout>
        )
    }
};
